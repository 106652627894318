<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Theo dõi hàng hóa</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="exportExcel()" @click="exportExcel()" class="md-raised md-primary btn-add">Export<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="orderName" @md-selected="getOrderSelected" :md-options="orders" @md-changed="getOrders"  @md-opened="getOrders">
                                    <label>Tất cả đơn hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.orderCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openOrder()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately :class="{'md-invalid': submitted && search.fromDate == null }">
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately :class="{'md-invalid': submitted && search.toDate == null }">
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo bill/booking/số tờ khai</label>
                                <md-input v-model="search.keyword"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo số cont</label>
                                <md-input v-model="search.serial"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div class="table-fixed" style="height: calc(100vh - 328px); overflow-x: auto; width: 100%;">
                        <table class="data-table" style="width: 3500px !important;">
                            <thead>
                                <tr>
                                    <th rowspan="2" style="background-color: #88D66C;">#</th>
                                    <th rowspan="2" style="width:200px; background-color: #88D66C;">Khách hàng</th>
                                    <th rowspan="2" style="width:200px; background-color: #88D66C;">Tên hàng hóa</th>                                    
                                    <th rowspan="2" style="width:150px; background-color: #88D66C;">Mã lô hàng</th>
                                    <th rowspan="2" style="width:150px; background-color: #88D66C;">Số tờ khai</th>
                                    <th rowspan="2" style="width:250px; background-color: #88D66C;">Hãng tàu</th>
                                    <th rowspan="2" style="width:120px; background-color: #88D66C;">Ngày tàu đến</th>
                                    <th rowspan="2" style="width:120px; background-color: #88D66C;">Ngày tàu đi</th>
                                    <th rowspan="2" style="width:150px; background-color: #88D66C;">Số bill/booking</th>
                                    <th rowspan="2" style="width:150px; background-color: #88D66C;">Số cont</th>
                                    <th rowspan="2" style="width:150px; background-color: #88D66C;">Loại cont</th>

                                    <th colspan="3" style="background-color: #88D66C;">Tuyến đường</th>

                                    <th rowspan="2" style="width:150px; background-color: #88D66C;">Số ngày miễn lưu DEM</th>
                                    <th rowspan="2" style="width:150px; background-color: #88D66C;">Ngày giao nhận</th>
                                    <th rowspan="2" style="width:150px; background-color: #88D66C;">Hạn lệnh</th>
                                    <th rowspan="2" style="width:150px; background-color: #88D66C;">Ngày lấy cont khỏi cảng</th>

                                    <th colspan="2" style="background-color: #88D66C;">Đơn vị vận chuyển</th>

                                    <th rowspan="2" style="width:150px; background-color: #FFD18E;">Số ngày miễn lưu DET</th>
                                    <th rowspan="2" style="width:150px; background-color: #FFD18E;">Hạn rỗng</th>

                                    <th colspan="3" style="background-color: #FFD18E;">Cược cont hãng tàu</th>
                                    <th colspan="3" style="background-color: #FFD18E;">Cược sửa chữa</th>
                                </tr>
                                <tr>
                                    <th style="width:150px; background-color: #88D66C;">Nơi lấy cont</th>
                                    <th style="width:150px; background-color: #88D66C;">Kho hàng</th>
                                    <th style="width:150px; background-color: #88D66C;">Nơi trả cont</th>

                                    <th style="width:120px; background-color: #88D66C;">Đơn vị phụ</th>
                                    <th style="width:120px; background-color: #88D66C;">Đơn vị chính</th>

                                    <th style="width:120px; background-color: #FFD18E;">Số tiền cược cont</th>
                                    <th style="width:120px; background-color: #FFD18E;">Ngày cược cont</th>
                                    <th style="width:120px; background-color: #FFD18E;">Ngày hoàn cược</th>

                                    <th style="width:120px; background-color: #FFD18E;">Số tiền cược sửa chữa</th>
                                    <th style="width:120px; background-color: #FFD18E;">Ngày cược sửa chữa</th>
                                    <th style="width:120px; background-color: #FFD18E;">Ngày hoàn cược</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td class="left">{{ item.customerName }}</td>
                                    <td class="left">{{ item.goodsName }}</td>                                    
                                    <td class="left">{{ item.orderCode }}</td>
                                    <td class="left">{{ item.declarationCode }}</td>
                                    <td class="left">{{ item.companyShipName }}</td>
                                    <td class="center">{{ item.dateShipCome }}</td>
                                    <td class="center">{{ item.dateShipGo }}</td>
                                    <td class="left">{{ item.noBooking }}</td>
                                    <td class="left">{{ item.serial }}</td>
                                    <td class="left">{{ item.unitName }}</td>
                                    <td class="left">
                                        <span v-if="item.locations.length >= 1">
                                            {{ item.locations[0] }}
                                        </span>
                                    </td>
                                    <td class="left">
                                        <span v-if="item.locations.length >= 2">
                                            {{ item.locations[1] }}
                                        </span>
                                    </td>
                                    <td class="left">
                                        <span v-if="item.locations.length >= 3">
                                            {{ item.locations[2] }}
                                        </span>
                                    </td>
                                    <td class="right">{{ item.numberDem }}</td>
                                    <td class="center">{{ item.dateOfReceipt }}</td>
                                    <td class="left">{{ item.commandDate }}</td>
                                    <td class="left">{{ item.dateTakeCont }}</td>
                                    <td class="left">{{ item.companySubDistribute }}</td>
                                    <td class="left">{{ item.companyDistribute }}</td>
                                    <td class="right">{{ item.numberDet }}</td>
                                    <td class="left">{{ item.emptyDate }}</td>
                                    <td class="right">{{ formatNumber(item.depositCont) }}</td>
                                    <td class="center">{{ item.dateDepositCont }}</td>
                                    <td class="center">{{ item.dateReturnDepositCont }}</td>
                                    <td class="right">{{ formatNumber(item.depositRepair) }}</td>
                                    <td class="center">{{ item.dateDepositRepair }}</td>
                                    <td class="center">{{ item.dateReturnDepositRepair }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <orderList ref="orderList" @close="closeOrder"/>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
    </div>
</template>

<script>
    import reportOrderService from '../../../api/reportOrderService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import orderList from '../../../components/popup/_OrderList.vue';
    import orderService from '../../../api/orderService';
    import { APP_CONFIG } from '@/utils/constants'
    import companyService from '../../../api/companyService';
    import customerList from '../../../components/popup/_CompanyList.vue';
    
    export default {
        components: {
            orderList,
            customerList
        },
        metaInfo: {
            title: 'Theo dõi hàng hóa'
        },
        data() {
            return {
                loadding: false,
                customerType: common.companyType.customer,
                search: { pageIndex: 1, pageSize: common.pageSize, customerId: 0, orderId: 0, keyword: '', serial: '', fromDate: common.dateNow, toDate: this.getRangeDate(7) },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                orderName: '',
                orders: [],
                customerName: '',
                customers: [],
                submitted: false
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.filter();
        },
        mounted() {
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            btnSearch(){
                this.submitted = true;
                if(this.search.fromDate == null){
                    messageBox.showWarning("Vui lòng chọn từ ngày");
                    return;
                }
                else if(this.search.toDate == null){
                    messageBox.showWarning("Vui lòng chọn tới ngày");
                    return;
                }   
                this.filter();
            },

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/report-order/export-report-cont?orderId=" + 
                this.search.orderId + "&keyword=" + this.search.keyword;

                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search = { pageIndex: 1, pageSize: common.pageSize, customerId: 0, keyword: '', serial: '', fromDate: this.getRangeDate(-7), toDate: common.dateNow, orderId: 0 };
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/report-cont?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                reportOrderService.getReportByCont(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

             //Order
             closeOrder(item){
                this.orderName = item.orderCode;
                this.search.orderId = item.id;
                this.$refs.orderList.close();
            },

            getOrderSelected(val){
                this.search.orderId = val.id;
                this.orderName = val.orderCode;
            },

            openOrder(){
                this.$refs.orderList.open();
            },

            getOrders(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, orderType: 1, contractIds: '', customerIds: '', ownerIds: '', code: searchTerm };
                orderService.getOrders(search).then((response) => {
                    if(response.statusCode == 200){
                        this.orders = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            orderName: function (val) { 
                if(val == ''){
                    this.search.orderId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>

<style scoped>
    .table-fixed table thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position:sticky;
        top: 35px;
        height: 35px;
    }
</style>
